import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { OrderSummarySubItem } from 'types/checkout';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { tablet } from 'styles/breakpoints';

interface PaymentDialogOrderSummaryProps {
  selectedProduct: Product | null;
  subItems?: OrderSummarySubItem[];
}

const Container = styled.div`
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: #fff;
  max-width: 21.4375rem;
  width: 100%;
  @media ${tablet} {
    max-width: unset;
  }
`;

const OrderSummaryItemContainer = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ paddingBottom }) => (!paddingBottom ? 0 : 0.75)}rem;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
  margin: 0.25rem 0 1rem 0;
`;

const PaymentDialogOrderSummary: FC<
  PaymentDialogOrderSummaryProps & HTMLAttributes<HTMLDivElement>
> = ({ selectedProduct, subItems, ...props }) => {
  const discountLabel: string | undefined = useSelector(
    (state: AppState) =>
      state?.config?.checkout?.paymentWindowLabels?.discountLabel,
  );
  if (!selectedProduct) {
    return null;
  }

  const product = getLocalisedProduct(selectedProduct);
  const trialSavings =
    100 -
    (Number(product.discountedTrialPrice) * 100) /
      Number(product.trialOldAmount);

  const trialDiscount =
    Number(product.trialOldAmount) - Number(product.discountedTrialPrice);
  return (
    <Container {...props}>
      <OrderSummaryItemContainer paddingBottom>
        <Text type="body">{product?.name.replace('Effecto ', '')}</Text>
        <Text type="body">
          {product.currency}
          {product.hasTrial ? product.trialOldAmount : product.regularPrice}
        </Text>
      </OrderSummaryItemContainer>
      <OrderSummaryItemContainer paddingBottom>
        <Text type="body" color="dark100">
          {discountLabel || 'Discount'} (-
          {product.hasTrial ? trialSavings.toFixed(0) : product.savings}
          %)
        </Text>
        <Text type="body" color="red110">
          -{product.currency}
          {product.hasTrial ? trialDiscount.toFixed(2) : product.discount}
        </Text>
      </OrderSummaryItemContainer>
      {(subItems || []).map((subItem, index) => (
        <OrderSummaryItemContainer key={index} paddingBottom>
          <Text type="body">{subItem.title}</Text>
          {subItem.value && (
            <Text type="body" color="primary">
              <span dangerouslySetInnerHTML={{ __html: subItem.value }} />
            </Text>
          )}
        </OrderSummaryItemContainer>
      ))}
      <Divider />
      <OrderSummaryItemContainer>
        <Text type="h4800">Total</Text>
        <Text type="h4800">
          {product.currency}
          {product.hasTrial ? product.discountedTrialPrice : product.finalPrice}
        </Text>
      </OrderSummaryItemContainer>
    </Container>
  );
};

export default PaymentDialogOrderSummary;
