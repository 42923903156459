import React, { FC } from 'react';
import { Props, Styles } from 'react-modal';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { DynamicImage, Modal, Text } from 'components';
import PaymentDialogOrderSummary from './OldPaymentDialogOrderSummary';
import PaymentsForm from './OldPaymentsForm';
import EmailInput from './OldEmailInput';
import { AppState } from 'state/types';
import PaymentSteps from './OldPaymentSteps';
import SecureFooter from 'assets/icons/payments/secure-footer.svg';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { useQuizData } from 'utils/hooks';
import { OrderSummarySubItem } from 'types/checkout';
import { Product } from 'types/product';
import PaymentStepsSVG from 'assets/images/checkout/payment-steps.svg';
import Chevron from 'assets/icons/back-arrow.svg';

interface PaymentDialogProps {
  selectedProduct: Product | null;
  onSuccess: (data: any) => Promise<void>;
  orderSummarySubItems?: OrderSummarySubItem[];
  onClose?: () => void;
  testTitle?: string;
}

const ContentContainer = styled.div`
  padding: 2.25rem 3rem 1.5rem 3rem;
  /* max-height: 638.656px; */
  max-width: 45rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    padding: 0rem 1rem 1rem 1rem;
  }
`;

const PaymentStepsContainer = styled.div`
  max-width: 15.75rem;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  text-align: center;
  padding: 1.5rem 0;
`;

const SecureFooterStyled = styled(SecureFooter)`
  margin-top: 1rem;
  width: 100%;
`;

const EmailInputContainer = styled.div`
  padding-bottom: 0.375rem;
`;

const SplitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  @media ${tablet} {
    flex-direction: column-reverse;
  }
`;

const BackBtnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
  @media ${mobile} {
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
`;

const BackBtnText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.024em;
  text-align: left;
  color: #00a676;
`;

const MethodsSVGStyled = styled(DynamicImage)`
  margin-top: 1.5rem;
`;

const TestTitle = styled.p`
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.02rem;
  padding: 0 0 1.25rem;
  @media ${mobile} {
    padding: 0 0 1rem;
  }
`;

const PaymentDialog: FC<PaymentDialogProps & Props> = ({
  selectedProduct,
  onSuccess,
  orderSummarySubItems,
  testTitle,
  onClose,
  onRequestClose,
  ...props
}) => {
  const { user } = useSelector((state: AppState) => state.user);

  const data = useQuizData('payments');

  const { isMobile, isTablet } = useQuery();

  const customStyle: Styles = {
    content: {
      padding: 0,
    },
  };

  const mobileStyle: Styles = {
    content: {
      padding: 0,
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      borderRadius: 0,
    },
  };

  return (
    <ContentContainer>
      {/* <PaymentStepsContainer>
        <PaymentSteps
          firstStepTitle={data?.firstStepTitle}
          secondStepTitle={data?.secondStepTitle}
          thirdStepTitle={data?.thirdStepTitle}
        />
      </PaymentStepsContainer> */}
      {/* <PaymentStepsSVG width={isTablet ? 305 : '100%'} /> */}
      <BackBtnContainer onClick={onRequestClose}>
        <Chevron />
        <BackBtnText>Back to plans</BackBtnText>
      </BackBtnContainer>
      {testTitle && <TestTitle>{testTitle}</TestTitle>}
      <SplitContainer>
        <PaymentsForm onSuccess={onSuccess} />
        <PaymentDialogOrderSummary
          subItems={orderSummarySubItems}
          selectedProduct={selectedProduct}
        />
      </SplitContainer>
      {/* <PaymentOptions alt="payment options" src={'payments/paymentImage.png'} /> */}
    </ContentContainer>
  );
};

export default PaymentDialog;

const PaymentOptions = styled(DynamicImage)`
  width: 100%;
`;
